.sequence video{
    background-color:transparent;
    height:100%;
}

.sequence #coursVideo{
    position:absolute;
    z-index:100;
    top:0;
    right:0;
    height:100%;
    width:50%;
    background:#fff;
}

.sequence #timeBarre{
    position:relative;
    bottom:0;
    width:100%;
    height:3%;
    background: rgb(160,160,160); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(160,160,160,1) 0%, rgba(76,76,76,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(160,160,160,1)), color-stop(100%,rgba(76,76,76,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(160,160,160,1) 0%,rgba(76,76,76,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(160,160,160,1) 0%,rgba(76,76,76,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(160,160,160,1) 0%,rgba(76,76,76,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(160,160,160,1) 0%,rgba(76,76,76,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0a0a0', endColorstr='#4c4c4c',GradientType=0 ); /* IE6-9 */
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    border:1px solid #000;
    border-top:2px solid #A0A0A0;
    border-bottom:0;
    margin-top: -1.65%;
}

.sequence .pauseLayer{
    width:51%;
    height:100%;
    display:none;
    position:absolute;
    z-index:2;
    top:0;
    left:0;
}

.sequence .pauseLayer.pause{
    width:100% !important;
}

.sequence .pauseLayer .logoPause{
    color: #fff;
    font-size: 16em;
    position: relative;
    text-align: center;
    z-index: 100;
    margin: 15% auto;
}

.sequence .pauseLayer.active{
    display:block;
}

.sequence .pauseLayer .txt.center{
    bottom: 24%;
    color: #888888;
    font-size: 1.7em;
    position: absolute;
    text-align: center;
    width: 100%;
}

.sequence .pauseLayer .logoPause:hover{
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    text-shadow:0 0 5px #fff;
}

.sequence #coursVideo .degrade{
    background: -moz-linear-gradient(left,  rgba(150,150,150,0.3) 0%, rgba(117,117,117,0) 7%, rgba(0,0,0,0) 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(150,150,150,0.3)), color-stop(7%,rgba(117,117,117,0)), color-stop(99%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  rgba(150,150,150,0.3) 0%,rgba(117,117,117,0) 7%,rgba(0,0,0,0) 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  rgba(150,150,150,0.3) 0%,rgba(117,117,117,0) 7%,rgba(0,0,0,0) 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  rgba(150,150,150,0.3) 0%,rgba(117,117,117,0) 7%,rgba(0,0,0,0) 99%); /* IE10+ */
    background: linear-gradient(to right,  rgba(150,150,150,0.3) 0%,rgba(117,117,117,0) 7%,rgba(0,0,0,0) 99%); /* W3C */
}

.sequence .progressBarre{
    background: rgb(191,210,85); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(191,210,85,1) 0%, rgba(142,185,42,1) 50%, rgba(114,170,0,1) 51%, rgba(158,203,45,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(191,210,85,1)), color-stop(50%,rgba(142,185,42,1)), color-stop(51%,rgba(114,170,0,1)), color-stop(100%,rgba(158,203,45,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(191,210,85,1) 0%,rgba(142,185,42,1) 50%,rgba(114,170,0,1) 51%,rgba(158,203,45,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(191,210,85,1) 0%,rgba(142,185,42,1) 50%,rgba(114,170,0,1) 51%,rgba(158,203,45,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(191,210,85,1) 0%,rgba(142,185,42,1) 50%,rgba(114,170,0,1) 51%,rgba(158,203,45,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(191,210,85,1) 0%,rgba(142,185,42,1) 50%,rgba(114,170,0,1) 51%,rgba(158,203,45,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfd255', endColorstr='#9ecb2d',GradientType=0 ); /* IE6-9 */
    height:100%;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    border-bottom:1px solid #000;
    text-align:right;
}

.sequence #coursVideo .contenuTxtVideo h2{
    font-size:1.3rem;
    margin-left:2rem;
}
.sequence #coursVideo .contenuTxtVideo h2:before{
    content: '\25A0';
}

.sequence #coursVideo .contenuTxtVideo img{
    display: block;
    height: auto;
    margin: 0 auto;
    width: 100%;
}

.sequence #coursVideo .contenuTxtVideo h2 span{
    font-family: "logoediser5";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position:absolute;
    left:0;
}

.sequence .titreVideo{
    position:absolute;
    z-index:10;
    top:1%;
    left:1%;
    color:#fff;
    text-shadow:1px 0 0px #000, -1px 0 0px #000, 0px 1px 0px #000, 1px -1px 0px #000;
    font-size: 1.5rem;
}

.sequence #coursVideo.repliVolet{
    transition: all 0.5s;
    width:0%;
}

.sequence #coursVideo.depliVolet{
    transition: all 0.5s;
    width:50%;
    margin-right: -0.119%;
}

.sequence .blackOpacity.active{
    background-color:#000;
    opacity:0.7;
    position:absolute;
    top:0;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    border-left:1px solid #fff;
}

.sequence #coursVideo .contenuTxtVideo{
    height: 83.5%;
    overflow: hidden;
    padding: 15% 3% 3% 8%;
    font-size: 1.1rem;
}

.sequence #coursVideo .contenuTxtVideo p{
    margin: 5% 5% 5% 0;
}

.sequence video{
    width:100%;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-position: center;
}

.sequence #bgProgressBarre .masque{
    display:none;
}

footer nav ul li#btnPlay .link{
    font-size: 1.15em;
    line-height: 1em;
    padding: 8%;
}

.sequence .puceLecture{
    display: inline-block;
    height: 159%;
    left: 0 !important;
    margin-right: -0.5em;
    position: relative;
    top: -32%;
    z-index: 10;
}

.sequence .cuePoint div.contentResponsive:hover{
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}


.sequence .cuePoint{
    height: 174%;
    position: absolute;
    top: -35%;
    width: 2.7%;
    z-index: 3;
}

.sequence .closeSequence{
    position: absolute;
    right: 4.5%;
    top: 2%;
    z-index: 101;
}

.sequence .closeSequence div.contentResponsive{
    box-shadow: none;
    font-family: "logoediser6";
    font-size: 2.5rem;
    position: absolute;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
}

.sequence footer nav #menuVideo{
    visibility: visible;
}


.BoxDialogue{
	position:relative;
	z-index:2000;
}

.bodyBox{
    min-height:10em;
    min-width: 25em;
    padding:1em;
}

#boxConfDelMessage .bodyBox {
    min-height: 5em;
}

#boxSommaire .bodyBox .scroller{
    height:27em;
}

#sommaireLvl1{
    width:30em;
}

#boxInfosLegales{
    width: 48rem;
    max-width: 700px;
    max-height: 500px;
    height: 50rem;
}

#boxVideo{
    width: 48rem;
    max-width: 720px;
}

#boxRevoirQuestion{
    max-width: 1000px;
    width: 58em;
}

#boxRevoirQuestion .zoneLeft{
    height: 33em;
    position: relative;
    width: 100%;
    background-color: #000;
}

#boxRevoirQuestion  .menuLigne {
    list-style-type: none;
}

#boxRevoirQuestion .zoneRight,
.statistiques #menuRevoirQuestion.menuLigne{
    width: 100%;
    position:relative;
}

#boxRevoirQuestion #menuRevoirQuestion{
    margin:0;
    padding:0;
    top:0; !important
}

#boxRevoirQuestion li#bilanRevoirQuestion.mauvaiseReponse{
    color:#fff;
    font-size:1.3em
}

#boxRevoirQuestion #menuRevoirQuestion .rep{
    position:relative;
}

#boxRevoirQuestion #menuRevoirQuestion li{
    text-align:center;
}

#boxRevoirQuestion #bilanRevoirQuestion{
    font-size:1.5em;
}

#boxRevoirQuestion #bilanRevoirQuestion span{
    display:inline-block;
    vertical-align: middle;
}

#boxRevoirQuestion #menuRevoirQuestion .rep{
    float:left;
}

#boxRevoirQuestion .scrollerBox {
    height: 17.6em;
}

#boxRevoirQuestion #menuRevoirQuestion #revoirA{
    margin-left:36%;
}

#boxRevoirQuestion #illustrationQuestion{
    background-repeat: no-repeat;
    background-size: 110% auto;
    height: 67%;
}

#boxRevoirQuestion .zoneQuestion ul li{
    position:relative;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wIYCRwxeKlOogAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAGUlEQVQI12OsPvqMkYGB4V+LlSQjAwMDAwAvPQP/GET0EwAAAABJRU5ErkJggg==');
    background-repeat: repeat-x;
    background-position: center 0.9em;
}

#boxRevoirQuestion .zoneRight.reponseFausse{
    color:#D60E0E;
}

#boxRevoirQuestion .zoneRight.reponseJuste,
#BoxDialogue .zoneLeft .zoneQuestion .lettre.bonneReponse{
    color:#6BC91B;
}

#boxRevoirQuestion .zoneLeft .zoneQuestion{
    background-color: #000;
    bottom: 0;
    color: #7bc5e6;
    min-height: 10em;
    padding: 2%;
    position: absolute;
    width: 96%;
    text-align: left;
}

#boxRevoirQuestion #zoneRightRevoirQuestion #menuRevoirQuestion .rep.maReponse:after{
    right: -22%;
    top: -63%;
}

#boxRevoirQuestion .zoneQuestion span{
    background-color: #000;
    padding-right: 0.33em;
}

#boxRevoirQuestion .zoneQuestion span.lettre{
    bottom:0;
    right:0;
    padding-right: 0em;
    padding-left: 0.33em;
    position: absolute;
    z-index: 1;
    color:#fff;
    font-family: arial, helvetica, sans-serif;
    background-color:#000;
    width:10%;
    font-size:1.1rem;
}

#boxRevoirQuestion .zoneLeft .zoneQuestion h3{
    color:#fff;
    font-size:1em;
    margin: 1% 0;
}

#boxRevoirQuestion .zoneLeft ul li{
    list-style: none;
}

#boxInfoFinFormation{
    margin-top:15%;
}

.index #BoxDialogue #boxInfosLegales .scrollerBox {
    height:20rem !important;
}

#BoxDialogue .scrollerBox{
    height:28.5em;
}

.BoxDialogue #boxAccueil{
	width:34rem;
}

.BoxDialogue #boxAccueil .bodyBox{
    padding: 3.5rem 4rem 4.5rem 8rem;
}

.BoxDialogue #boxAccueil .bodyBox:before{
    content:url("../images/fleche.png");
    left: 5%;
    position: absolute;
    top: 20%;
}

.BoxDialogue .msg{
	position:absolute;
}

.index #BoxDialogue .scrollerBox {
    height: 13.5rem !important;
}

.BoxDialogue .dialogueBox{
	height:80%;
	width:30rem;
	max-width:500px;
	max-height:850px;
	top:5rem;
	right:0;
	background:#ffffff;
}

#boxPremiereConnexion{
    width: 31em;
    max-width: none;
}

#BoxDialogue #boxPremiereConnexion .iconeBox {
   margin: -2% 0 0 -6%;
}

.BoxDialogue .msg .background,
.BoxDialogue .msg .background .headBox,
.BoxDialogue .msg .background .suiteMessege .repondreMessage{
	background:#ffffff;
	border-radius:0.5em;
	box-shadow:2px 2px 4px #555;
}

.BoxDialogue .msg .background .suiteMessege .repondreMessage{
    border-top: 2px solid #bbb;
    border-radius:0 0 0.5em 0.5em;
}

.BoxDialogue .msg .background .headBox{
	box-shadow:none;
	border-bottom-left-radius:0rem;
	border-bottom-right-radius:0rem;
	padding:1rem 0rem 0.4rem 3.4rem;
	background: #4fc7f0; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmYzdmMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjM0JSIgc3RvcC1jb2xvcj0iIzRmYzdmMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5YmRlZjYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(left,  #4fc7f0 0%, #4fc7f0 34%, #9bdef6 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#4fc7f0), color-stop(34%,#4fc7f0), color-stop(100%,#9bdef6)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  #4fc7f0 0%,#4fc7f0 34%,#9bdef6 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  #4fc7f0 0%,#4fc7f0 34%,#9bdef6 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  #4fc7f0 0%,#4fc7f0 34%,#9bdef6 100%); /* IE10+ */
	background: linear-gradient(to right,  #4fc7f0 0%,#4fc7f0 34%,#9bdef6 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4fc7f0', endColorstr='#9bdef6',GradientType=1 ); /* IE6-8 */
	box-shadow:inset 1px 0px 1px #31AFD8;
}

.BoxDialogue #recalcule .background .headBox{
    background:#fff;
    padding:0;
}

#listeBox #recalcule .bodyBox img{
    padding:0 1em 0;
}

#listeBox #recalcule .bodyBox .textWidth{
    width:17em;
    text-align: justify;
}

#listeBox #recalcule .bodyBox .textWidthMessageEleve{
    width:50em;
    text-align: justify;
}

#listeBox #recalcule .bodyBox h3{
    line-height:1em;
    font-weight: normal;
}



.BoxDialogue #recalcule .background .headBox h2 {
    display:none;
}

.BoxDialogue .msg .background .headBox h2{
	color: #FFFFFF;
    font-size: 1.7rem;
    font-weight: normal;
    line-height: 2rem;
    font-family : helvetica_neue_lt_std87HvCn,Helvetica,sans-serif, Times, serif;
    margin: 0;
}

.BoxDialogue #boxMessage .background .headBox h2 span.mail{
	background-color: #FF0000;
    border-radius: 0.6rem;
    box-shadow: 0 0 1px #FFFFFF, -1px 1px 2px #9B0707 inset;
    font-size: 0.8rem;
    font-weight: bold;
    left: 0.8rem;
    line-height: 1.1rem;
    padding: 0.1% 1.2% 0 1.3%;
    position: absolute;
    top: 0.3rem;
}

.BoxDialogue .dialogueBox .background table{
	border:0;
	width:100%;
	border-collapse:collapse;
	font-size:0.9rem;
	border-top:1px solid #fff;
	border-right:1px solid #fff;
	border-left:1px solid #fff;
}

.BoxDialogue .dialogueBox .background .listeMessages td{
	cursor:pointer;
    vertical-align: middle;
}

.BoxDialogue .dialogueBox .background .listeMessages tr:hover,
.BoxDialogue .dialogueBox .background .listeMessages tr.select,
.BoxDialogue .dialogueBox .background .suiteMessege .headMessage,
.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage{
	background-color:#e2edfb;
}

.BoxDialogue .dialogueBox .background .listeMessages tr.nonLu{
	font-weight:bold;
	color:#4ac7f3;
}

.BoxDialogue .dialogueBox .background .listeMessages tr.active{
    background-color:#eee;
}

.BoxDialogue .dialogueBox .background table td,
.BoxDialogue .dialogueBox .background table th,
.BoxDialogue .dialogueBox .background .listeMessages tr{
	height:2rem;
}

.BoxDialogue .dialogueBox .background table th{
	background-color:#eaeaea;
	color:#646f77;
	text-shadow:1px 1px 0 #fff;	
	font-weight:normal;
	text-align:left;
	vertical-align:middle;
	height:2rem;
}

.BoxDialogue .msg .background table .logo{
	width:11%;
}

.BoxDialogue .dialogueBox .background hr{
	background-color: #BFBFBF;
    height: 2px;
    width: 100%;
    border:0;
}

#BoxDialogue .dialogueBox .background .listeMessages .nonLu td.logo{
    float: left;
    font-size: 3em;
    margin: -20% 0 9% 11%;
    text-align: center;
    visibility: visible;
}

.BoxDialogue .msg .background .suiteMessege .headMessage{
    border-top: 2px solid #bbb;
    padding: 0.6em 0;
}

.BoxDialogue .dialogueBox .background .suiteMessege .headMessage table{
	border-top:0;
}

.BoxDialogue .dialogueBox .background .suiteMessege .headMessage table th{
	background-color:transparent;
	width:12%;
	text-align:right;
}
.BoxDialogue .dialogueBox .background .suiteMessege .headMessage table td{
	padding-left:0.3rem;
	font-size:1.1rem;
}

.BoxDialogue .dialogueBox .background .suiteMessege .headMessage table tr,
.BoxDialogue .dialogueBox .background .suiteMessege .headMessage table td,
.BoxDialogue .dialogueBox .background .suiteMessege .headMessage table th{
	height:1em;
}

.BoxDialogue .dialogueBox .background .suiteMessege  .bodyMessage,
.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage{
	padding:1rem;
}

.BoxDialogue .dialogueBox .background .suiteMessege  .bodyMessage{
	font-size:0.9rem;
	border-top-left-radius:0rem;
	border-top-right-radius:0rem;
    height: 6.5em;
    padding-bottom: 4rem;
}

.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage h2{
	color:#646f77;
	text-align:center;
	font-weight:normal;
	font-size:1rem;
	padding:0;
	text-shadow:1px 1px 0px #fff;
}

.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage{
    bottom: 0;
    box-shadow: -1px 0 0 #fff inset;
    padding: 0;
    position: absolute;
    width: 100%;
}

.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage .inputTextarea{
	border:1px solid #BFBFBF;
	width:calc(96% - 2px);
    max-width:calc(96% - 2px);
    min-width: calc(96% - 2px);
    padding: 2%;
	height:6rem;
}

.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage .envoiForm{
    margin: 0.2em;
    text-align: center;
}

#boxSignalBug {
    width:600px;
}

#boxSignalBug_btnAnnule,#boxSignalBug_btnValide,
.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage input,
#boxConfDelMessage .bodyBox .envoiForm input{
    border: 0 none;
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
    box-shadow: 0 0 2px #FFFFFF;
    color: #FFFFFF;
    font-size: 0.8rem;
    height: 1.8rem;
    width: 8rem;
}

#boxConfDelMessage .bodyBox .envoiForm input {
    margin: 1rem;
}

#boxSignalBug_btnValide,
.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage .inputSubmit,
#boxConfDelMessage .bodyBox .envoiForm .inputSubmit{
    background-color: #58C9F1;
}

#boxSignalBug_btnAnnule,
.BoxDialogue .dialogueBox .background .suiteMessege .repondreMessage .inputAnnule,
#boxConfDelMessage .bodyBox .envoiForm .inputAnnule{
    background-color: #d3d9d9;
    color:#777;
}

#boxSignalBug textarea{
    width:99%;
    height: 110px;
}

#boxSignalBug select{
    width:100%
}

#boxSignalBug .envoiForm{
    text-align: center;
}

.BoxDialogue .dialogueBox .bodyBox{
    padding:1rem;
    min-height: 100px;
    position:relative;
}

#BoxDialogue .iconeBox{
    color:#fff;
    float:left;
    margin: -2.5% 0 0 -10%;
}

#BoxDialogue #boxSommaire table tr td{
    vertical-align: middle;
}

#BoxDialogue #boxSommaire .puceNiv0{
    font-size: 1.95rem;
    line-height:2.1rem;
    vertical-align: top;
}

#BoxDialogue #boxSommaire .titreLigneSommaireNiv0{
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0 0.5rem 0;
}

#BoxDialogue #boxSommaire .titreLigneSommaireNiv0 .full{
    line-height: 2rem;
}

#BoxDialogue #boxSommaire .desactive:not(.deplis) .puceNiv0{
    color: #000 !important;
}

#BoxDialogue #boxSommaire .puceNiv1{
    font-size: 1.2rem;
    line-height: 0.9rem;
    width: 1.6rem;
}

#BoxDialogue #boxSommaire .desactive:not(.deplis) .titreLigneSommaireNiv0 .full{
    background-color: transparent !important;
}

#BoxDialogue #boxSommaire .titreLigneSommaireNiv0 .full{
    padding-left: 1.5%;
    width: 98.5%;
    height:auto;
}

#BoxDialogue #boxSommaire .active .titreLigneSommaireNiv0 .full,
#BoxDialogue #boxSommaire .deplis .titreLigneSommaireNiv0 .full{
    color:#fff !important;
}

#BoxDialogue #boxSommaire .ligneNiv0.deplis{
    border-bottom:solid 2px;
}

#BoxDialogue #boxSommaire .spacer td{
    height:0px;
}

#BoxDialogue #boxSommaire .tblNiv1{
    margin-top:0.3rem;
}

#BoxDialogue #boxSommaire .puceNiv1,
#BoxDialogue #boxSommaire .titreLigneSommaireNiv1{
    vertical-align: top;
}

#BoxDialogue #boxSommaire .titreLigneSommaireNiv2{
    font-weight:normal;
    color:#000;
}

#BoxDialogue #boxSommaire .ligneNiv2.nolink .titreLigneSommaireNiv2{
    color:#bbb;
}

#BoxDialogue #boxDessinNomapad{
    max-width: none;
    max-height: none;
    width:63em;
}

#BoxDialogue #boxPremiereConnexion .bodyBox td.input{
    text-align: right;
    width: 69%;
}

#BoxDialogue #boxPremiereConnexion .bodyBox td.input input{
    width:85%;
}

#BoxDialogue #boxPremiereConnexion .bodyBox .info{
    margin:5% 0;
    font-style:italic;
}

#BoxDialogue #boxPremiereConnexion .bodyBox .ligneValide{
    text-align:center;
}

#boxTricherie .iconeWindows{
    font-size: 2.2em;
    margin: 0 1% 0 -14%;
}

#boxMessage .divListeMessage{
    height: 19em;
    padding-bottom: 3em;
}

#boxMessage .divListeMessage.active{
    height: 19em;
    padding-bottom: 0em;
}

#boxMessage .suiteMessege{
    position: relative;
}

#boxMessage .listeMessages th.date{
    width:10%;
}

#boxMessage #sendMessage .sujet{
    margin:0.5em;
    text-align:center;
}

#boxMessage #sendMessage .sujet input{
    width:60%;
    box-shadow:none;
    color:#000;
    height: 2em;
    font-size:1em;
    font-weight:normal;
}

#boxMessage .listeMessages th.corbeille,
#boxMessage .listeMessages td.corbeille {
    font-size: 1.15em;
    text-align: center;
    width: 6%;;
}

#boxConfDelMessage .envoiForm{
    margin-top:1em;
}

#boxConfDelMessage .iconeWindows {
    float: left;
    font-size: 2.5rem;
    line-height: 0.5em;
    margin: 0 0 0 -8%;
}

.BoxDialogue #boxConfDelMessage {
    width: 27rem;
}














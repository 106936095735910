
.partie #contenuAjax .sequences .sequence .cadre td{
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjMyIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY0JSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjEyIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.32) 0%, rgba(255,255,255,0.12) 64%, rgba(255,255,255,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.32)), color-stop(64%,rgba(255,255,255,0.12)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.32) 0%,rgba(255,255,255,0.12) 64%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(255,255,255,0.32) 0%,rgba(255,255,255,0.12) 64%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(255,255,255,0.32) 0%,rgba(255,255,255,0.12) 64%,rgba(255,255,255,0) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.32) 0%,rgba(255,255,255,0.12) 64%,rgba(255,255,255,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#52ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-8 */
}



.partie #contenuAjax .sequences,
.partie #contenuAjax .illustrationLateral{
	height:100%;
}
.partie #contenuAjax .illustrationLateral{
	width:25%;
	-webkit-background-size:100%;
	-moz-background-size:100%;
	-o-background-size:100%;
	background-size:100%;
    background-repeat : no-repeat !important;
}
.partie #contenuAjax .sequences{
	width:75%;
}

.partie #contenuAjax .sequences .sequence.titre{
	width:98%;
	height:9%;
	padding:2% 0 0 1.5%;
}

.partie #contenuAjax .sequences .sequence.titre h3{
	margin:0;
    padding:0;
	font-size:1.5rem;
    font-family: 'helvetica_neue_lt_std87HvCn';
    font-weight:normal;
    color:#000;
}

.partie #contenuAjax .illustrationLateral .degrade{
	height:100%;
	width:100%;
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI5JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC43NCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 29%, rgba(0,0,0,0.74) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(29%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.74))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 29%,rgba(0,0,0,0.74) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 29%,rgba(0,0,0,0.74) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 29%,rgba(0,0,0,0.74) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 29%,rgba(0,0,0,0.74) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#bd000000',GradientType=0 ); /* IE6-8 */
}

.partie #contenuAjax .sequences .sequence{
	position:relative;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
    box-sizing:border-box;
    border-top:1px solid #fff;
    border-left:1px solid #fff;
}

.partie #contenuAjax .sequences .sequence.nbVignettesInferieurEgaleA6{
    width:33.332%;
    height:45.49%;
}

.partie #contenuAjax .sequences .sequence.nbVignettesInferieurEgaleA4{
    width:50%;
    height:45.49%;
}

.partie #contenuAjax .sequences .sequence.nbVignettesInferieurEgaleA10{
    width:25%;
    height:30.326%;
}

.partie #contenuAjax .sequences .sequence .cadre{
	width:100%;
	height:30%;
	position:absolute;
	bottom:0;
	text-align:center;
}
.partie #contenuAjax .sequences .sequence:hover .cadre{
    transition: all 0.2s;
	color:#fff;
}

.partie #contenuAjax .sequences .sequence .cadre tbody,
.partie #contenuAjax .sequences .sequence .cadre tr,
.partie #contenuAjax .sequences .sequence .cadre td{
	height: 100%;
    vertical-align: middle;
    width: 100%;
	border:none;
}
.partie #contenuAjax .sequences .sequence .cadre td h4{
    display: inline;
    font-size: 1.2em;
    line-height: 1em;
    padding: 2%;
}

.partie #contenuAjax .sequences .sequence .imgBg,
.partie #contenuAjax .sequences .sequence .imgBg .opacityBg{
	height:100%;
	width:100%;
}

.partie #contenuAjax .sequences .sequence.nonActif{
    background-color: #f1f2f3;
}




















.index header nav .menuPrincipal .retour,
.index header nav .menuPrincipal .home,
.index header nav .menuPrincipal .option,
.index .ligneHaut.logo{
    display:none;
}

.index .imgLogo{
    background-image: url("https://www.cdnediser.com/packweb3/image/logo_PW_640.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 6em;
    width: 42%;

}

.index section h2.couleurBarreHorizontal{
    background: transparent !important;
}

.index .millieu h2 .degradeHorizontal .titreModulePrincipal{
    color:#666;
    font-weight: bold;
}

.titreLivret{
    background: #4286ce;
    background: -moz-linear-gradient(left, #4286ce 0%, #4286ce 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#4286ce), color-stop(100%,#2f69ad));
    background: -webkit-linear-gradient(left, #4286ce 0%,#2f69ad 100%);
    background: -o-linear-gradient(left, #4286ce 0%,#2f69ad 100%);
    background: -ms-linear-gradient(left, #4286ce 0%,#2f69ad 100%);
    background: linear-gradient(to right, #4286ce 0%,#2f69ad 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4286ce', endColorstr='#2f69ad',GradientType=1 )
}

.index header .sousTitre{
    margin-left: 10rem !important;
}

.index section.login.largeurLogin{
    margin: 14% auto 0 !important;
    width: 59em;
}

.index .login .lblLogin,
#listeBox #recalcule .bodyBox h3{
    color: #43A2E6;
    font-family: arial,helvetica,sans-serif;
    font-size: 1.2rem;
    letter-spacing: 0.04rem;
    line-height: 2.7rem;
    text-transform: uppercase;
}

.index .login .inputLogin,
.index #BoxDialogue .inputMail{
    background-color: #FFFFFF;
    border: 1px solid #666666;
    border-radius: 5px;
    font-size: 1.5rem;
    width: 17%;
    padding: 0.4rem;
    color: #108FA9;
    text-align:center;
    min-width: 19rem;
}

.index #BoxDialogue .label{
    margin-top:1.5rem;
    font-size:1.2rem;
}

.index #BoxDialogue .inputMail{
    width:80%;
    margin:3% 7%;
}

.index #BoxDialogue input.submit{
    padding: 0.4% 5% !important;
    line-height: 1.5em !important;
    margin-bottom: 6px;
}

.index input.submit{
    background-color: #43A2E6 !important;
    border: 0 none !important;
    border-radius: 18px !important;
    box-shadow: 0 2px 3px #333333 !important;
    font-family: arial,helvetica,sans-serif !important;
    color: #FFFFFF !important;
    font-size: 1.4rem !important;
    margin-top: 1% !important;
    margin-left:0 !important;
    padding: 0.15% 3% !important;
}

.index input.submitfree{
    background-color: #64b8ac !important;
    border: 0 none !important;
    border-radius: 18px !important;
    box-shadow: 0 2px 3px #333333 !important;
    font-family: arial,helvetica,sans-serif !important;
    color: #FFFFFF !important;
    font-size: 1.4rem !important;
    margin-top: 1% !important;
    margin-left:0 !important;
    padding: 0.15% 3% !important;
}

.index footer.login{
    position: absolute !important;
    width: 100% !important;
    bottom: 0;
    font-family: arial,helvetica,sans-serif;
    text-align: center !important;
}

.index .login .retenirMdp{
    color: #108FA9;
    font-family: arial,helvetica,sans-serif;
    font-size: 1.1rem;
    margin-top: 7%;
}

.index .login .retenirMdp a,
.index a#InfosLegales{
    color: #43A2E6;
    font-size: 0.8rem;
}

.index .login .retenirMdp a{
    font-size: 1.2rem !important;
    font-weight: bold;
    text-shadow: 0px 0px 23px #bbb;
}

.index a#InfosLegales{
    font-size: 0.9rem;
}

footer.login.largeurLogin{
    font-size:0.9rem;
    color:#668BBF;
}



.index .btnType{
    height:33%;
    width:100%;
    position:relative;
}

.index .btnType .blockBtn{
    position:absolute;
}

.index .btnType .degradeAccueil{
    z-index:2;
}

.index .btnType .asset{
    z-index:3;
    color:#fff;
    font-family: 'helvetica_neue_lt_std47LtCn';
}

.index .btnType .progessionBarre{
    width:55%;
    font-size:1rem;
    min-width: 120px;
}

.index .btnType .barre{
    width:96%;
    padding:1.15%;
}

.index .btnType .barre .contentBarre{
    height:100%
}

.index .btnType .stats .progressionChiffre{
    font-size:3rem;
    font-family: 'helvetica_neue_lt_std87HvCn';
    line-height: 2.55rem;
    margin-left: 2%;
}

.index .btnType .stats .progressionChiffre .pourcentage{
    font-size:2rem;
}

.index .btnType .btnCodeRoute{
    font-size:1.2rem;
    position:absolute;
    bottom:5%;
    right:3%;
    border-radius:1rem;
    box-shadow: 0px 0px 0.2rem #333;
    padding:0.3rem 1rem;
}

.index .btnType .imgBackground,
.index .btnType .asset{
    background-size: cover !important;
}

.index .btnType .stats{
    position:absolute;
    left:2.5%;
    bottom:5%;
    width: 20.7%;
    min-width: 210px;
}

.index .btnType .progessionBarre .label{
    text-align:center;
}

.index .btnType .barre{
    background-color: #fff;
    border-radius:0.2rem;
    height: 1.15rem;
}

.index .coursCodeRoute .imgBackground{
    background:url('../images/bg3.jpg') top left 100%;
}

.index .coursCodeRoute.nolink .imgBackground{
    background:url('../images/bg3nb.jpg') top left 100%;
}

.index .coursCodeRoute .degradeAccueil{
    background: -moz-linear-gradient(left,  rgba(80,191,203,1) 0%, rgba(80,191,203,1) 26%, rgba(80,191,203,0) 70%, rgba(80,191,203,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(80,191,203,1)), color-stop(26%,rgba(80,191,203,1)), color-stop(70%,rgba(80,191,203,0)), color-stop(100%,rgba(80,191,203,0)));
    background: -webkit-linear-gradient(left,  rgba(80,191,203,1) 0%,rgba(80,191,203,1) 26%,rgba(80,191,203,0) 70%,rgba(80,191,203,0) 100%);
    background: -o-linear-gradient(left,  rgba(80,191,203,1) 0%,rgba(80,191,203,1) 26%,rgba(80,191,203,0) 70%,rgba(80,191,203,0) 100%);
    background: -ms-linear-gradient(left,  rgba(80,191,203,1) 0%,rgba(80,191,203,1) 26%,rgba(80,191,203,0) 70%,rgba(80,191,203,0) 100%);
    background: linear-gradient(to right,  rgba(80,191,203,1) 0%,rgba(80,191,203,1) 26%,rgba(80,191,203,0) 70%,rgba(80,191,203,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#50bfcb', endColorstr='#0050bfcb',GradientType=1 );
}

/*.index .coursCodeRoute.hover .blockBtn{
    box-shadow: inset 0px 0px 0.3em #fff;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -kthtml-transition: all 0.2s linear;
    transition: all 0.2s linear;
}*/


.index .coursCodeRoute .imgBackground.hover{
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.index .coursCodeRoute .asset{
    background:url('../images/code_ligne.png') top left;
}


.index .coursCodeRoute .contentBarre{
    background-color:#118EAA;
    border-radius:0.2rem;
}

.index .coursCodeRoute .btnCodeRoute{
    background-color: #50BFCB;
}

.index .examenBlanc{
    margin:0.3% 0;
}

.index .examenBlanc .imgBackground{
    background:url('../images/bg2.jpg') top left 100%;
}

.index .examenBlanc.nolink .imgBackground{
    background:url('../images/bg2nb.jpg') top left 100%;
}

.index .examenBlanc .degradeAccueil{
    background: -moz-linear-gradient(left,  rgba(254,193,72,1) 0%, rgba(254,193,72,1) 26%, rgba(254,193,72,0) 70%, rgba(254,193,72,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(254,193,72,1)), color-stop(26%,rgba(254,193,72,1)), color-stop(70%,rgba(254,193,72,0)), color-stop(100%,rgba(254,193,72,0)));
    background: -webkit-linear-gradient(left,  rgba(254,193,72,1) 0%,rgba(254,193,72,1) 26%,rgba(254,193,72,0) 70%,rgba(254,193,72,0) 100%);
    background: -o-linear-gradient(left,  rgba(254,193,72,1) 0%,rgba(254,193,72,1) 26%,rgba(254,193,72,0) 70%,rgba(254,193,72,0) 100%);
    background: -ms-linear-gradient(left,  rgba(254,193,72,1) 0%,rgba(254,193,72,1) 26%,rgba(254,193,72,0) 70%,rgba(254,193,72,0) 100%);
    background: linear-gradient(to right,  rgba(254,193,72,1) 0%,rgba(254,193,72,1) 26%,rgba(254,193,72,0) 70%,rgba(254,193,72,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fec148', endColorstr='#00fec148',GradientType=1 );
}

.index .examenBlanc.hover .degradeAccueil{
    /*Si stéphane change d'avis...*/
}

.index .examenBlanc .asset{
    background:url('../images/examen_blanc.png') top left;
}

.index .examenBlanc .contentBarre{
    background-color:#FA9F1A;
    border-radius:0.2rem;
}

.index .examenBlanc .btnCodeRoute{
    background-color: #FEC148;
}

.index .livretApprentissage .imgBackground{
    background:url('../images/bg1.jpg') top left 100%;
    background-size: 100% 100%;
}

.index .livretApprentissage .degradeAccueil{
    background: -moz-linear-gradient(left,  rgba(68,137,208,1) 0%, rgba(68,137,208,1) 26%, rgba(68,137,208,0) 70%, rgba(68,137,208,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(68,137,208,1)), color-stop(26%,rgba(68,137,208,1)), color-stop(70%,rgba(68,137,208,0)), color-stop(100%,rgba(68,137,208,0)));
    background: -webkit-linear-gradient(left,  rgba(68,137,208,1) 0%,rgba(68,137,208,1) 26%,rgba(68,137,208,0) 70%,rgba(68,137,208,0) 100%);
    background: -o-linear-gradient(left,  rgba(68,137,208,1) 0%,rgba(68,137,208,1) 26%,rgba(68,137,208,0) 70%,rgba(68,137,208,0) 100%);
    background: -ms-linear-gradient(left,  rgba(68,137,208,1) 0%,rgba(68,137,208,1) 26%,rgba(68,137,208,0) 70%,rgba(68,137,208,0) 100%);
    background: linear-gradient(to right,  rgba(68,137,208,1) 0%,rgba(68,137,208,1) 26%,rgba(68,137,208,0) 70%,rgba(68,137,208,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4489d0', endColorstr='#004489d0',GradientType=1 );
}

.index .livretApprentissage.hover .degradeAccueil{
    /*Si stéphane change d'avis...*/
}

.index .livretApprentissage .asset{
    background:url('../images/suivi_formation.png') top left;
}

.index .livretApprentissage .contentBarre{
    background-color:#4489D0;
    border-radius:0.2rem;
    box-shadow: inset 0.05rem 0rem 0.1rem #CC9C43, inset -0.05rem 0rem 0.1rem #CC9C43, inset 0rem 0.15rem 0.15rem #CC9C43;
}

.index .livretApprentissage .btnCodeRoute{
    background-color: #4489D0;
}

.index .btnAddlevel{
    border: 0.2rem solid #FFFFFF;
    border-radius: 1.4rem;
    box-shadow: 0 0 0.2rem #333333;
    color: #666666;
    font-size: 1.6rem;
    margin: 3% 5rem 0;
    padding: 0.5rem 0rem;
    text-align: center;
    width: 19rem;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 51%, rgba(194,220,242,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(51%,rgba(255,255,255,1)), color-stop(100%,rgba(194,220,242,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 51%,rgba(194,220,242,1) 100%);
    background: -o-linear-gradient(top,  rgba(255,255,255,1) 51%,rgba(194,220,242,1) 100%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,1) 51%,rgba(194,220,242,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 51%,rgba(194,220,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c2dcf2',GradientType=0 );
}

.index .btnAddlevel.nolink{
    color:#fff;
    text-shadow:1px 1px 1px #777;
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #d3d3d3 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#d3d3d3));
    background: -webkit-linear-gradient(top,  #ffffff 0%,#d3d3d3 100%);
    background: -o-linear-gradient(top,  #ffffff 0%,#d3d3d3 100%);
    background: -ms-linear-gradient(top,  #ffffff 0%,#d3d3d3 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#d3d3d3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d3d3d3',GradientType=0 );

}

.index header.login div{
    color: #A7AFB2;
    font-size: 1.3rem;
    margin: -2% 0 0;
}

.index footer{
    height:4%;
}

.dialogueBox .bodyBox .submit{
    margin:0.5em;
}

.index #boxPremiereConnexion.viewObligatoire #frmComplementInfo input.obligatoire {
    border:1px solid #ff0000;
}

.index .tuto{
    fbackground-color: #fff;
    font-size: 3em;
    position: absolute;
    right: 0;
    text-shadow: 2px 0 1px #fff, -2px 0 1px #fff, 0 2px 1px #fff, 0 -2px 1px #fff;
    top: 0;
}

.index #cours .tuto{
    color:#50BFCB;
}

.index #examens .tuto{
    color:#FEC148;
}

.index #statistiques .tuto{
    color:#4489D0;
}
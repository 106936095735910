#contenuAjax b, #contenuAjax strong{
    font-family: 'helvetica_neue_lt_std87HvCn';
    font-weight:normal;
}

#contenuAjax h3{
    color: #0088CC;
    font-family: 'helvetica_neue_lt_std47LtCn';
    font-size: 2rem;
    font-weight: normal;
    margin: 2.7rem 0 1rem;
    padding: 0;
}

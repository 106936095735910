.statistiques .t {
    display: table;
    width: 100%;
    height: 100%;
}

.statistiques .tc {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.statistiques .rel {
    position: relative;
}

.statistiques #book {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.statistiques #book .page img {
    max-width: 100%;
    height: 100%;
}

.statistiques .contenuMonAAC{
    width:87%;
    height:100%;
}

.module .carreModule{
    border:1px #eee solid;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
}

.module .carreModule .overLayer{
    opacity:0;
    z-index:2;
}

.module .carreModule:hover{
    cursor:pointer;
}

.module .carreModule:hover .overLayer{
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    -o-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    opacity:0.3;
}

.module .carreModule .titreModule,
.module .carreModule .statModule{
    position:relative;
    z-index:3;
    text-shadow:1px -1px 0px #fff, -1px 1px 0px #fff, 1px 1px  0px #fff, -1px -1px 0px #fff;
}

.module .carreModule .titreModule{
    font-size:1.3rem;
    position:absolute;
    width:70%;
    top:0.4rem;
    left:0.9rem;;
    text-align:left;
    font-family: 'helvetica_neue_lt_std87HvCn';
}

.module .carreModule .statModule{
    font-size:1.6rem;
    font-weight:normal;
    position:absolute;
    width:30%;
    top:0.4rem;;
    right:0.4rem;;
    text-align:right;
    min-width:36px;
    font-family : helvetica_neue_lt_std87HvCn,Helvetica,sans-serif, Times, serif;
}

.module .carreModule.nonActif .statModule{
    color:#666 !important;
}




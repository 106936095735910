.oldnavigateur h2{
    color: #EF2A7C;
    font-size: 4vh;
    margin-top: 10%;
    text-align: center;
}

.oldnavigateur table{
    margin: 3% auto 0;
    color:#83A5C5;
}

.oldnavigateur table td{
    font-size: 1.2vw;
    vertical-align: bottom;
    width: 20%;
}

.oldnavigateur footer{
    font-size: 1.2vw;
    margin: 5% auto 0;
    width: 47%;
    color:#000;
}

.oldnavigateur table img{
    -khtml-opacity:0.9;
    -moz-opacity : 0.9;
    -ms-filter: "alpha(opacity=90)";
    opacity:0.9;
}

.oldnavigateur table img:hover{
    -khtml-opacity:1;
    -moz-opacity : 1;
    -ms-filter: "alpha(opacity=100)";
    opacity:1;
}

.oldnavigateur .login.largeurLogin{
    margin: auto;
    position: relative;
    width: 59%;
}
.choixnavigateur h2{
    color: #EF2A7C;
    font-size: 4vh;
    margin-top: 3%;
    text-align: center;
}

.choixnavigateur table{
    margin: 3% auto 0;
    color:#83A5C5;
}

.choixnavigateur table td{
    font-size: 1.3vw;
    vertical-align: bottom;
    width: 50%;
}

.choixnavigateur footer{
    font-size: 1.5vw;
    margin: 5% auto 0;
    width: 47%;
    color:#000;
}

.choixnavigateur .affTablette:hover,
.choixnavigateur .affTel:hover{
    cursor: pointer;
}

.choixnavigateur table img{
    -khtml-opacity:0.9;
    -moz-opacity : 0.9;
    -ms-filter: "alpha(opacity=90)";
    opacity:0.9;
}

.choixnavigateur table img:hover{
    -khtml-opacity:1;
    -moz-opacity : 1;
    -ms-filter: "alpha(opacity=100)";
    opacity:1;
}

.choixnavigateur h2{
    margin:2em 0 -1em 0;
}
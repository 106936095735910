.resultats #contenuAjax{
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #f9f9f9 26%, #e3edfb 60%, #f8b01b 60%, #f5a200 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(26%,#f9f9f9), color-stop(60%,#e3edfb), color-stop(60%,#f8b01b), color-stop(100%,#f5a200));
    background: -webkit-linear-gradient(top,  #ffffff 0%,#f9f9f9 26%,#e3edfb 60%,#f8b01b 60%,#f5a200 100%);
    background: -o-linear-gradient(top,  #ffffff 0%,#f9f9f9 26%,#e3edfb 60%,#f8b01b 60%,#f5a200 100%);
    background: -ms-linear-gradient(top,  #ffffff 0%,#f9f9f9 26%,#e3edfb 60%,#f8b01b 60%,#f5a200 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#f9f9f9 26%,#e3edfb 60%,#f8b01b 60%,#f5a200 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5a200',GradientType=0 );

}

.resultats .contentResultat{
    height: 100%;
    margin: 0 13% 0 7%;
    width: 80%;
}

.resultats #contenuAjax .titre h3{
    margin: 0.8rem 0 0;
    padding:0;
    line-height: 2rem;
    text-shadow:none !important;
}

.resultats #contenuAjax .reussi .titre h3{
    color: #6AC91B;
}
.resultats #contenuAjax .rate .titre h3{
    color: #FF0000;
    text-shadow: 2px 0 0 #D50F10, -2px 0 0 #D50F10, 0 2px 0 #D50F10, 0 -2px 0 #D50F10;
}

.resultats #contenuAjax .rate .cadreFautes{
    background-color: #ff0000;
}

.resultats #contenuAjax .reussi .cadreFautes{
    background-color:#6AC91B;
}


.resultats #contenuAjax .titre h3 b{
    font-size:4.3rem;
}

.resultats #contenuAjax .titre{
    margin-top:5%;
    cursor:default;
}

.resultats #contenuAjax .cadreFautes{
    background-color: #6AC91B;
    color: #FFFFFF;
    margin: 0 0 0 1rem;
    padding: 0.5rem 1.8% 0.4rem;
    border-radius:0.5rem;
}

.resultats #contenuAjax .cadreFautes b{
    font-size: 6rem;
    line-height: 4.3rem;
    margin-right: 0.5rem;
}

.resultats #contenuAjax .cadreFautes .blanc{
    font-size: 2.4em;
    line-height: 2.7rem;
}

.resultats #contenuAjax .cadreFautes .noir{
    font-size: 1.1rem;
    line-height: 2rem;
}

.resultats #contenuAjax .bilanReponse{
    background-color: #FFFFFF;
    border-radius: 0.7rem;
    box-shadow: 0rem 0.2rem 0.4rem #555555;
    height: 51%;
    margin-top: 2%;
    width: 100%;
    position:relative;
}

.resultats #contenuAjax .numQuestions{
    width:87%;
    margin-top: 1.5%;
    margin-left: 1%;
    position:relative;
    z-index:100;
}

.resultats .numQuestions table,
.resultats .numQuestions table tbody{
    width:100%;
    height:100%;
    border-collapse: collapse;
    border:none !important;
}

.resultats .numQuestions table td{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 94%;
    border: medium none !important;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    width: 10%;
}

.resultats .numQuestions table td span{
    display: block;
    font-size: 1.7em;
    margin: 36% 0;
    color:#fff;
}

.resultats .numQuestions table td.reponseJuste span{
    color:#aaa;
}

.resultats #contenuAjax .illustration{
    width:15%;
}

.resultats #contenuAjax .illustration{
    height: 166%;
    position: absolute;
    right: -12.5%;
    top: -19%;
    width: 36%;
}

.resultats #contenuAjax .reussi .illustration{
    background-image: url("../images/gagne.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.resultats #contenuAjax .rate .illustration{
    background-image: url("../images/perdu.png");
    background-size: 70% auto;
    background-position: right center;
    background-repeat: no-repeat;
}

.resultats .expliquation{
    margin-top:1%;
    color:#A24B30;
}

.resultats #menuExamen{
    visibility: hidden;
}

.resultats .expliquation .fontEdiser{
    font-size:0.8rem
}

.resultats footer nav ul li .link,
.resultats footer nav ul li .nolink{
    width:auto;
}

footer nav #menuResultat .link,
footer nav #menuResultat .nolink{
    background: rgb(254,254,254); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(254,254,254,1) 0%, rgba(223,231,233,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(254,254,254,1)), color-stop(100%,rgba(223,231,233,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(254,254,254,1) 0%,rgba(223,231,233,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(254,254,254,1) 0%,rgba(223,231,233,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(254,254,254,1) 0%,rgba(223,231,233,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(254,254,254,1) 0%,rgba(223,231,233,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#dfe7e9',GradientType=0 ); /* IE6-9 */
    border-radius: 0.75em;
    box-shadow: 0 0 0 0.1rem #FFFFFF, 0 0 0.1rem #DDDDDD inset;
    color: #666666;
    font-size: 1.8rem;
    padding: 0em 0.8em;
    text-shadow: 1px 1px 0 #FFFFFF;
    box-shadow:none\9;
    text-shadow:none\9;
    border-radius:0\9;
    white-space:nowrap;
}

.resultats footer nav #menuResultat{
    visibility: visible;
    top: 7%;
}

footer nav #menuResultat .link:after,
footer nav #menuResultat .link:before,
footer nav #menuResultat .nolink:before,
footer nav #menuResultat .nolink:after{
    content:"";
}
html,body{
    height:100%;
    color:#666666;
}

html>body header,
header{
	height: 12.27%;
	min-height:100px;
    position:relative;
}

header #logoPrincipal{
    background-image:url("../images/code_ligne.png");
    background-repeat: no-repeat;
    background-size: 64em auto;
    height:108%;
    width:80%;
    position:absolute;
    color:#108FA9;
    top:0;
    left:-4%;
    z-index: 9;
}

.cours header #logoPrincipal{
    background-image:url("../images/code_ligne.png");
    background-repeat: no-repeat;
    background-size: 64em auto;
}

.examens header #logoPrincipal{
    background-image:url("../images/examen_blanc.png");
    background-repeat: no-repeat;
    background-size: 59em auto;
}

.statistiques header #logoPrincipal{
    background-image:url("../images/suivi_formation.png");
    background-repeat: no-repeat;
    background-size: 58em auto;
}

.stats header #logoPrincipal{
    background-image:url("../images/suivi_formation.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.mobile h2.choixnav{
    margin-top:4em;
}

.mobile #ListeChoixAffichage{
    width:100%;
}

.mobile #ListeChoixAffichage td{
    width:50%;
}

header .titreMenu, section .couleurBarreHorizontal{
	width:87%;
	position:relative;
    z-index:10;
}

header .titreMenu{
	height:100%;
}

header .barreUtilisateur{
	height:35px;
	width:100%;
}

header .barreUtilisateur span{
	background-color:#e2edfb;
	border-radius:0 0 0.6rem 0.6rem;
	display: inline-block;
	white-space: nowrap;
	height:100%;
	line-height:2rem;
}

header .barreUtilisateur .nomUser{
    padding: 0 53px 0 70px;
	height:100%;
	color:#666;
	font-size:0.9rem;
    text-align:center;
	position:relative;
    font-family: Arial, Verdana, sans-serif;
	
}

header .barreUtilisateur .nomUser .logoUser{
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-size: 1.5rem;
    left: 5%;
    position: absolute;
}

header .barreUtilisateur .logOut{
    color: #666;
    -webkit-font-smoothing: antialiased;
    font-size: 1.3em;
    margin-left: 5px;
    text-align: center;
    width: 45px;
}

header nav ul.menuLigne, header nav{
	width:100%;
	margin:0;
	padding:0;
    height: 3.2em;
	bottom:0;
	position:absolute;
    z-index:100;
}

header .btnMenuHaut{
    height: 0;
	padding-bottom: 6%;
    position: relative;
}

header .btnMenuHaut .contentResponsive, header .btnMenuHaut{
	max-height:70px;
	max-width:70px;
	bottom: 0;
}

#contenuAjax{
	height:100%;
	width:100%;
}

.millieu .titreModulePrincipal{
    margin:0 1%;
    text-align:left;
}

section h2.titre .ligneHaut{
    font-size: 1.45em;
    left: 0.2rem;
    position: absolute;
    top: -0.5rem;
    width: 100%;
}

section .couleurBarreHorizontal{ margin:0.6rem 0 0.4rem}

section .couleurBarreHorizontal .degradeHorizontal{
    color: #FFFFFF;
    font-size: 1.4rem;
    line-height: 1.5em;
    font-weight: normal;
}

section .couleurBarreHorizontal span{
	font-family : helvetica_neue_lt_std47LtCn,Helvetica,sans-serif, Times, serif;
}

section .couleurBarreHorizontal span strong{
    font-family : helvetica_neue_lt_std87HvCn,Helvetica,sans-serif, Times, serif;
    font-weight:normal;
}

section.content{
	position: relative;
    width: 100%;
    height: 100%;
}

section.content .contentResponsive,
.accueil .etiquetteModule{
	background-color:#fff;
	-webkit-box-shadow: 2px 3px 8px #888;
	-moz-box-shadow: 2px 3px 8px #888;
	box-shadow: 2px 3px 8px #888;
}

section.content .contentResponsive:before,
section.content .contentResponsive:after,
.accueil .etiquetteOmbre:before,
.accueil .etiquetteOmbre:after{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 20px;
  left: 36px;
  width: 50%;
  top: 80%;
  max-width:350px;
  background: #222;
  -webkit-box-shadow: 0 22px 8px #222, 20px 20px 30px #222, -20px 20px 30px #222;
  -moz-box-shadow: 0 22px 8px #222, 20px 20px 40px #222, -20px 20px 40px #222;
  box-shadow: 0 22px 8px #222, 20px 20px 30px #222, -20px 20px 30px #222;
  box-shadow: 0 22px 8px #222, 20px 20px 30px #222, -20px 20px 30px #222;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

section.content .contentResponsive:after,
.accueil .etiquetteOmbre:after{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 36px;
  left: auto;
}

section .content .contentResponsive.univer div#contenuAjax{
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	background-position : center center;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    border: 1px solid #FFFFFF;
}

section .contentResponsive .degrade{
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ5JSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4yNSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 74%, rgba(0,0,0,0.15) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(74%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.15))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 74%,rgba(0,0,0,0.15) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 74%,rgba(0,0,0,0.15) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 74%,rgba(0,0,0,0.15) 100%); /* IE10+ */
	background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.15) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#40000000',GradientType=1 ); /* IE6-8 */
	height:100%;
	width:100%;
}

footer{
    position:relative;
    height:10.5%;
}

footer nav ul{
	padding:0;
	margin:0;
    position:absolute;
    width:100%;
    top:0;
}

footer nav{
	text-align:center;
    position: relative;
    margin-top: 0.6%;
}

footer nav .menuLigne{
    visibility:hidden;
}

footer nav .menuFourtous li div.contentResponsive,
.sequence footer nav ul#menuVideo.menuVideo li div.contentResponsive{
	-webkit-background-size:auto 100%;
	-moz-background-size:auto 100%;
	-o-background-size:auto 100%;
	background-size:auto 100%;
}

footer nav ul li{
    display: inline-block;
    font-size: 4em;
    position: relative;
    margin-left:1em;
}

footer nav ul li .link{
    float: left;
}

footer nav ul li .pictoTimer,
footer nav ul li .link{
    -background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: left;
    color: #727272;
    padding:1px;
    line-height: 68px;
    width:1em;
}

.resultats footer nav ul li .link{
    line-height: 45px;
}
